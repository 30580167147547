import { faBars, faClose, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ActionButtons(props) {
  return (
    <>
      <a
        href="tel:+1 972-679-8245"
        id="btnAction"
        className="button action-button"
      >
        <FontAwesomeIcon icon={faPhone} />
        Call Me
      </a>
      <button
        type="button"
        className="button icon-button"
        id="btnMenu"
        onClick={props.handleMenu}
      >
        <FontAwesomeIcon icon={!props.menu ? faBars : faClose} />
      </button>
    </>
  );
}
export default ActionButtons;
