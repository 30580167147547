function ServicesItemLoad() {
  return (
    <div className="service-row-load">
      <div className="service-row-image-load"></div>
      <div className="service-row-data-load">
        <span className="title">
          <span></span>
          <span></span>
        </span>
        <span className="desc">
          <span></span>
          <span></span>
          <span></span>
        </span>
        <span className="button"></span>
      </div>
    </div>
  );
}
export default ServicesItemLoad;
