import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import ContactRow from "../common/ContactRow";

function Contact() {
  return (
    <>
      <Helmet>
        <title>WOW SOLUTION 360 - CONTACT</title>
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="head-title">
            <h1>Contact</h1>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row-content">
            <p>For more information, you can contact us through:</p>
            <ContactRow 
              icon={faPhone}
              title="Phone"
              text="+1 972-679-8245"
              link="tel:+19726798245"
            />
            <ContactRow 
              icon={faEnvelope}
              title="Email"
              text="wowsolution360@aol.com"
              link="mailto:wowsolution360@aol.com"
            />
            <ContactRow 
              icon={faLocationDot}
              title="Address"
              text="115 E 2nd Street , Irving, TX, United States"
              link="https://maps.app.goo.gl/vscTRzJYHrXmieSE7"
            />
          </div>
        </div>
      </section>
    </>
  );
}
export default Contact;
