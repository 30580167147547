import { Link } from "react-router-dom";
import logotype from "../../assets/image/logotype.svg";

function Logo() {
  return (
    <Link to="/" className="logo">
      <img src={logotype} alt="WOW SOLUTIONS 360" />
    </Link>
  );
}
export default Logo;
