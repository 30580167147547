import { useEffect, useState } from "react";
import ScrollToTop from "./ScrollToTop";
import Header from "./Header"
import Menu from "./Menu";
import Main from "./Main";
import Glass from "./Glass";
import Footer from "./Footer";

function Layout() {

  const [menu, setMenu] = useState(false);

  const handleMenu = () => {
    setMenu(!menu);
    setGlass(!glass);
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === 'KeyM' && event.altKey) {
        handleMenu();
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }
  }, );

  const [glass, setGlass] = useState(false);

  const handleGlass = () => {
    setGlass(!glass);
    setMenu(false);
  }

  return (
    <div>
      <ScrollToTop />
      <Header menu={menu} handleMenu={handleMenu} />
      <Menu menu={menu} handleMenu={handleMenu} />
      <Main />
      <Footer />
      <Glass glass={glass} handleGlass={handleGlass} />
    </div>
  )
}
export default Layout;