import { Helmet } from "react-helmet";
import { db } from "../../firebase/config";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import Carousel from "../common/Carousel";
import img1 from "../../assets/images/tempered-glass.png";
import img2 from "../../assets/images/laminated-glass.png";
import img3 from "../../assets/images/insulated-glass.png";
import img4 from "../../assets/images/low-e-glass.png";
import ItemBox from "../common/ItemBox";
import ServicesItem from "../common/ServicesItem";
import ServicesItemLoad from "../common/ServicesItemLoad";

function Home() {
  const items = [
    {
      image: img1,
      title: "Tempered Glass",
      desc: "High impact resistance.",
    },
    {
      image: img2,
      title: "Laminated Glass",
      desc: "Enhances safety, soundprooft.",
    },
    {
      image: img3,
      title: "Insulated Glass",
      desc: "Energy efficient, noice reduction.",
    },
    {
      image: img4,
      title: "Low-E Glass",
      desc: "Reflects thermal radiation.",
    },
  ];

  const [services, setServices] = useState([]);

  useEffect(() => {
    const servicesRef = collection(db, "services");

    getDocs(servicesRef)
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        setServices(data);
      })

      .catch((error) => {
        console.log("Error al obtener los datos: ", error);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>WOW SOLUTION 360</title>
      </Helmet>

      <Carousel />

      <section className="section">
        <div className="container">
          <div className="grid">
            {items.map((item) => (
              <ItemBox
                image={item.image}
                title={item.title}
                desc={item.desc}
                path={item.path}
              />
            ))}
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row-caption">
            <h2>Services</h2>
            <span className="line"></span>
            <p>Services Transforming Spaces With Glass.</p>
          </div>
          <div className="column">
            {services.length === 0 ? (
              <>
                <ServicesItemLoad />
                <ServicesItemLoad />
                <ServicesItemLoad />
                <ServicesItemLoad />
              </>
            ) : (
              services
                .slice(-2)
                .map((service) => (
                  <ServicesItem
                    key={service.id}
                    title={service.title}
                    image={service.image}
                    desc={service.desc}
                    path={service.path}
                    show_service={service.show_service}
                  />
                ))
            )}
          </div>
          <div className="row-action">
            <Link to="/services" className="button primary-button">
              See All Services
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}
export default Home;
