import "./assets/style/app.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/common/Layout";
import Home from "./components/pages/Home";
import Services from "./components/pages/Services";
import Contact from "./components/pages/Contact";
import NoFound from "./components/pages/NoFound";
import About from "./components/pages/About";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="*" element={<NoFound />} />
          </Route>

          <Route path="/home" element={<Navigate to="/" replace />} />
          <Route path="/index" element={<Navigate to="/" replace />} />
          <Route path="/index.html" element={<Navigate to="/" replace />} />
          <Route path="/inicio" element={<Navigate to="/" replace />} />
          <Route path="/servicios" element={<Navigate to="/services" replace />} />
          <Route path="/contacto" element={<Navigate to="/contact" replace />} />
          <Route path="/about-us" element={<Navigate to="/about" replace />} />
          <Route path="/acerca" element={<Navigate to="/about" replace />} />
          <Route path="/nosotros" element={<Navigate to="/about" replace />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
