// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDH2743C7IZipYp10mgXoBMiGxV50iqTpg",
  authDomain: "wow-solution-360.firebaseapp.com",
  projectId: "wow-solution-360",
  storageBucket: "wow-solution-360.appspot.com",
  messagingSenderId: "327081490529",
  appId: "1:327081490529:web:dc2ace1bce978f69145220",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export { db };
