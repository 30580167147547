import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

function NavMenu(props) {
  return (
    <nav className="nav-menu">
      <ul className="nav-list">
        <li className="nav-item">
          <Link to="/" className="nav-link" onClick={props.click}>
            Home
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/services" className="nav-link" onClick={props.click}>
            Services
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/contact" className="nav-link" onClick={props.click}>
            Contact
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/about" className="nav-link" onClick={props.click}>
            About
          </Link>
        </li>
        <li className="nav-item">
          <a
            href="tel:+1 972-679-8245"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link"
          >
            Call Us
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="sm" />
          </a>
        </li>
      </ul>
    </nav>
  );
}
export default NavMenu;
